import React from 'react';
import { ICalloutProps } from '@belong/types';
import { BLOCKS } from '@contentful/rich-text-types';
import { COLOURS, FLAIRS } from '@belong/themes';
import useRuntime from '@belong/providers/runtime';
import { RichText, Copy } from '../../styles/Typography';
import { Column } from '../../ui';
import { CalloutContainer, CalloutColumns, CalloutIcon, CalloutImage, CalloutTitle } from './Callout.styles';

const renderCalloutTitle = (fontColor, titleVariant) => ({
  // eslint-disable-next-line react/display-name
  [BLOCKS.PARAGRAPH]: (_, children) => (
    <CalloutTitle as="span" variant={titleVariant} hasColor={fontColor} isBold>
      {children}
    </CalloutTitle>
  )
});

const renderCalloutDescription = fontColor => ({
  // eslint-disable-next-line react/display-name
  [BLOCKS.PARAGRAPH]: (_, children) => (
    <Copy as="span" hasColor={fontColor} variant="small">
      {children}
    </Copy>
  )
});

export const getColumnGap = (isDesktop: boolean, hasDescription: boolean) => {
  if (isDesktop) {
    return hasDescription ? 'xxsmall' : 'xsmall';
  }
  return hasDescription ? 'small' : 'xsmall';
};

export const getIconSize = (isDesktop: boolean, hasDescription: boolean) => {
  if (isDesktop) {
    return hasDescription ? 'small' : 'xsmall';
  }
  return hasDescription ? 'xsmall' : 'xxsmall';
};

/**
 * Callouts are molecules used inside components, such as the Plan Card or Primary Banner to emphasise product promotions.
 */
export const Callout: React.FC<ICalloutProps> = props => {
  const {
    className,
    colour = 'PASSION_POP',
    icon,
    title,
    description,
    backgroundImage,
    extendedDictionary,
    available = true,
    fullwidth = false,
    ...elementProps
  } = props;
  const { isDesktopViewport } = useRuntime();

  const fontColor = colour === FLAIRS.BLACK ? COLOURS.BELONG_BLUE_LIGHT : COLOURS.BLACK;
  const titleVariant = isDesktopViewport ? 'medium' : 'small';
  const classNames = [className, 'callout-container'].filter(Boolean).join(' ');

  if (!available) {
    return (
      <CalloutContainer
        className={classNames}
        background={'UNAVAILABLE'}
        tabIndex={-1}
        isFullwidth={fullwidth}
        {...(elementProps || {})}
      >
        {/** TBC: use the title or description. Possibly derived from another component in the plan card */}
        {description && (
          <RichText
            {...description}
            dictionary={extendedDictionary}
            renderNode={renderCalloutTitle(fontColor, titleVariant)}
          />
        )}
      </CalloutContainer>
    );
  }

  return (
    <CalloutContainer
      className={`${className} callout-container`}
      background={colour}
      hasTitle={!!title}
      hasDescription={!!description}
      hasBackgroundImage={!!backgroundImage}
      isFullwidth={fullwidth}
      {...(elementProps || {})}
    >
      <CalloutColumns
        space={getColumnGap(isDesktopViewport, !!description)}
        alignX="left"
        hasTitle={!!title}
        hasDescription={!!description}
        hasBackgroundImage={!!backgroundImage}
      >
        {icon && (
          <Column width="content" alignX="center">
            <CalloutIcon name={icon.name} size={getIconSize(isDesktopViewport, !!description)} hasColor={fontColor} />
          </Column>
        )}

        {/** Fallback routine here to handle current instances of the callout which have only a description */}

        {!title && description ? (
          <Column width="flex">
            <RichText
              {...description}
              dictionary={extendedDictionary}
              renderNode={renderCalloutTitle(fontColor, titleVariant)}
            />
          </Column>
        ) : (
          <Column width="flex">
            {title && (
              <RichText
                {...title}
                dictionary={extendedDictionary}
                renderNode={renderCalloutTitle(fontColor, titleVariant)}
              />
            )}

            {description && (
              <RichText
                {...description}
                dictionary={extendedDictionary}
                renderNode={renderCalloutDescription(fontColor)}
              />
            )}
          </Column>
        )}
      </CalloutColumns>
      {description && backgroundImage && <CalloutImage role="img" backgroundImage={backgroundImage.src} />}
    </CalloutContainer>
  );
};

Callout.displayName = 'Callout';

export default Callout;
