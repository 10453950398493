import { FlairsValue, MaybeStyledCSS } from '@belong/types';
import { CALLOUT_FLAIR_COLOURS, mediaMap } from '@belong/themes';
import styled, { css } from 'styled-components';
import { Copy } from '../../styles/Typography/Copy/Copy';
import { Icon } from '../../Icon';
import { Columns } from '../../ui';

const BACKGROUND_IMAGE_WIDTH = {
  xs: '5.8rem',
  lg: '6rem'
};

export const CalloutContainer = styled.div<{
  background: FlairsValue | 'UNAVAILABLE';
  hasTitle?: boolean;
  hasDescription?: boolean;
  hasBackgroundImage?: boolean;
  isFullwidth?: boolean;
}>`
  background: ${({ background }) => CALLOUT_FLAIR_COLOURS[background]};
  border-radius: 8px;
  display: flex;
  overflow: hidden;

  ${({ isFullwidth, hasDescription, hasTitle }): MaybeStyledCSS =>
    isFullwidth
      ? css`
          width: 100%;
        `
      : hasDescription &&
        hasTitle &&
        css`
          min-width: 24rem;
          max-width: 38rem;
        `};
`;

export const CalloutColumns = styled(Columns)<{
  hasTitle?: boolean;
  hasDescription?: boolean;
  hasBackgroundImage?: boolean;
}>`
  height: fit-content;

  flex-shrink: ${({ hasBackgroundImage }) => (hasBackgroundImage ? 1 : 0)};

  padding: ${({ hasTitle, hasDescription }) => {
    if (hasDescription) {
      if (!hasTitle) {
        return '0.4rem 0.8rem';
      }
      return '0.8rem 1rem';
    }
    return '0.4rem 0.8rem';
  }};
`;

export const CalloutIcon = styled(Icon)`
  margin-top: 0.2rem;
`;

export const CalloutImage = styled.div<{ backgroundImage: string }>`
  ${({ backgroundImage }) => css`
    background-image: url(${backgroundImage});
  `};

  ${mediaMap(
    BACKGROUND_IMAGE_WIDTH,
    (width: string) => css`
      width: ${width};
      background-size: ${width} auto;
    `
  )}

  background-position: center;
  background-repeat: no-repeat;
  margin-left: 1rem;
`;

export const CalloutTitle = styled(Copy)`
  display: inline-flex;
  align-items: center;
  height: 100%;
  vertical-align: middle;

  & span[role='presentation'] {
    margin-right: 4px;
    top: unset;
  }
`;
